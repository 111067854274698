// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #4BB1E0;
$secondary: #daf7fc;
$tertiary: #abdefd;
$corduroy: #3f4443;
$accent: #9c27b0;

$dark: #1d1d1d;

$positive: #21ba45;
$negative: #c10015;
$info: #31ccec;
$warning: #CE9E00;

$cell_width: 144px;
$cell_height: 46px;

$new_cell_width: 140px;
$new_cell_height: 45px;

$header_height: 60px;
$splitter_sep_height: 40px;
$left_menu_width: 60px;


$left_column_mobile_width: 54px;
$left_column_desktop_width: 235px;

$cell_mobile_width: 50px;
$cell_mobile_height: 40px;

$cell_desktop_width: 140px;
$cell_desktop_height: 46px;

$header_desktop_height: 100px;
$header_mobile_height: 65px;



// roomdoo colors
$roomdoo_red: #F24E1E;
$roomdoo_lightgray: #D0D0D0;
$roomdoo_lightgray2: #EDEDED;
$roomdoo_lightgray3: #F0F0F0;
$roomdoo_lightgray4: #F0FCFF;
$roomdoo_lightgray5: #F6F6F6;
$roomdoo_green: #008000;
$roomdoo_blue: #00B5E2;
$roomdoo_blue2: #007b9a;
$roomdoo_dark_blue:#00445D;


// scroll

$scrollbar_width: 8px;
$scrollbar_height: 8px;

$input_height: 40px;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
.debug-box {
  border: 2px solid red;
}
.debug-box-green {
  border: 2px solid green;
}

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $secondary;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: #{$scrollbar_width};
  height: #{$scrollbar_height};
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 4px;
}

@font-face {
  font-family: barlow-regular;
  src: url(../assets/fonts/barlow/Barlow-Regular.ttf);
}
@font-face {
  font-family: barlow-bold;
  src: url(../assets/fonts/barlow/Barlow-Bold.ttf);
}
@font-face {
  font-family: barlow-medium;
  src: url(../assets/fonts/barlow/Barlow-Medium.ttf);
}
@font-face {
  font-family: barlow-semi-condensed-regular;
  src: url(../assets/fonts/barlow/BarlowSemiCondensed-Regular.ttf);
}
@font-face {
  font-family: barlow-semi-bold;
  src: url(../assets/fonts/barlow/Barlow-SemiBold.ttf);
}
body {
  font-family: barlow-regular;
  letter-spacing: 0.01px;
  overflow: hidden;
}

@media (min-resolution: 1.25dppx) {
  #right-drawer, 
  #index-container,
  .button-open-drawer,
  .login-container, 
  #page-dashboard,
  #partners-page,
  #page-transactions,
  #invoices-page {
    zoom: 80%;
  }
  @media(min-width: 1024px) {
    #full-screen-stepper {
      height: 90% !important;
    }
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  appearance: textfield;
  -moz-appearance:textfield;
}